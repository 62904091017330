<template>
  <div>
    <b-modal
      id="primary"
      ref="modal"
      centered
      :visible="showModal"
      cancel-variant="outline-secondary"
      :title="$t('calcBlindage.modal.title')"
      :ok-title="$t('calcBlindage.modal.ok')"
      :cancel-title="$t('calcBlindage.modal.cancel')"
      @cancel="toogleCreateModal"
      @close="toogleCreateModal"
      @ok="sendCalc"
    >
      <div v-if="selectedBarrierName === 'primary'">
        <p class="mt-2">
          {{ $t('calcBlindage.modal.fullFillInfo') }}
        </p>
        <p>1) {{ $t('calcBlindage.calcPerDoseLimit') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="primary-p">
                {{ $t('glossary.primary.P') }}
              </b-tooltip>
              <label for="P">P</label>
              <b-form-input
                id="primary-p"
                v-model="formprimary.P"
                type="number"
                placeholder="P"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="primary-u">
                {{ $t('glossary.primary.U') }}
              </b-tooltip>
              <label for="U">U</label>
              <b-form-input
                id="primary-u"
                v-model="formprimary.U"
                type="number"
                placeholder="U"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="primary-t">
                {{ $t('glossary.primary.T') }}
              </b-tooltip>
              <label for="T">T</label>
              <b-form-input
                id="primary-t"
                v-model="formprimary.T"
                type="number"
                placeholder="T"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="primary-d">
                {{ $t('glossary.primary.d') }}
              </b-tooltip>
              <label for="d">d</label>
              <b-form-input
                id="primary-d"
                v-model="formprimary.d"
                type="number"
                placeholder="d"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <p>2) {{ $t('calcBlindage.cinturaoArg') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="primary-dm">
                {{ $t('glossary.primary.dm') }}
              </b-tooltip>
              <label for="dm">dm</label>
              <b-form-input
                id="primary-dm"
                v-model="formprimary.dm"
                type="number"
                placeholder="dm"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="primary-cmax">
                {{ $t('glossary.primary.cMax') }}
              </b-tooltip>
              <label for="cMax">cMax</label>
              <b-form-input
                id="primary-cmax"
                v-model="formprimary.cMax"
                type="number"
                placeholder="cMax"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <p>3) {{ $t('calcBlindage.espOtm') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="primary-uotm">
                {{ $t('glossary.primary.Uotm') }}
              </b-tooltip>
              <label for="Uotm">Uotm</label>
              <b-form-input
                id="primary-uotm"
                v-model="formprimary.Uotm"
                type="number"
                placeholder="Uotm"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="primary-totm">
                {{ $t('glossary.primary.Totm') }}
              </b-tooltip>
              <label for="Totm">Totm</label>
              <b-form-input
                id="primary-totm"
                v-model="formprimary.Totm"
                type="number"
                placeholder="Totm"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="primary-height">
                {{ $t('glossary.primary.height') }}
              </b-tooltip>
              <label for="height">height</label>
              <b-form-input
                id="primary-height"
                v-model="formprimary.height"
                type="number"
                placeholder="height"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="primary-width">
                {{ $t('glossary.primary.width') }}
              </b-tooltip>
              <label for="width">width</label>
              <b-form-input
                id="primary-width"
                v-model="formprimary.width"
                type="number"
                placeholder="width"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="primary-c">
                {{ $t('glossary.primary.C') }}
              </b-tooltip>
              <label for="C">C</label>
              <b-form-input
                id="primary-c"
                v-model="formprimary.C"
                type="number"
                placeholder="C"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="primary-n">
                {{ $t('glossary.primary.n') }}
              </b-tooltip>
              <label for="n">n</label>
              <b-form-input
                id="primary-n"
                v-model="formprimary.n"
                type="number"
                placeholder="n"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="selectedBarrierName === 'obliqueBeam'">
        <p class="mt-2">
          {{ $t('calcBlindage.modal.fullFillInfo') }}
        </p>
        <p>1) {{ $t('calcBlindage.calcPerDoseLimit') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="obliqueBeam-p">
                {{ $t('glossary.obliqueBeam.P') }}
              </b-tooltip>
              <label for="P">P</label>
              <b-form-input
                id="obliqueBeam-p"
                v-model="formobliqueBeam.P"
                type="number"
                placeholder="P"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="obliqueBeam-d">
                {{ $t('glossary.obliqueBeam.D') }}
              </b-tooltip>
              <label for="D">D</label>
              <b-form-input
                id="obliqueBeam-d"
                v-model="formobliqueBeam.d"
                type="number"
                placeholder="D"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="obliqueBeam-U">
                {{ $t('glossary.obliqueBeam.U') }}
              </b-tooltip>
              <label for="U">U</label>
              <b-form-input
                id="obliqueBeam-U"
                v-model="formobliqueBeam.U"
                type="number"
                placeholder="U"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="obliqueBeam-T">
                {{ $t('glossary.obliqueBeam.T') }}
              </b-tooltip>
              <label for="T">T</label>
              <b-form-input
                id="obliqueBeam-T"
                v-model="formobliqueBeam.T"
                type="number"
                placeholder="T"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <p>2) {{ $t('calcBlindage.espOtm') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="obliqueBeam-n">
                {{ $t('glossary.obliqueBeam.n') }}
              </b-tooltip>
              <label for="n">n</label>
              <b-form-input
                id="obliqueBeam-n"
                v-model="formobliqueBeam.n"
                type="number"
                placeholder="n"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="obliqueBeam-c">
                {{ $t('glossary.obliqueBeam.C') }}
              </b-tooltip>
              <label for="C">C</label>
              <b-form-input
                id="obliqueBeam-c"
                v-model="formobliqueBeam.C"
                type="number"
                placeholder="C"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="obliqueBeam-height">
                {{ $t('glossary.obliqueBeam.height') }}
              </b-tooltip>
              <label for="height">height</label>
              <b-form-input
                id="obliqueBeam-height"
                v-model="formobliqueBeam.height"
                type="number"
                placeholder="height"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="obliqueBeam-width">
                {{ $t('glossary.obliqueBeam.width') }}
              </b-tooltip>
              <label for="width">width</label>
              <b-form-input
                id="obliqueBeam-width"
                v-model="formobliqueBeam.width"
                type="number"
                placeholder="width"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="obliqueBeam-Uotm">
                {{ $t('glossary.obliqueBeam.Uotm') }}
              </b-tooltip>
              <label for="Uotm">Uotm</label>
              <b-form-input
                id="obliqueBeam-Uotm"
                v-model="formobliqueBeam.Uotm"
                type="number"
                placeholder="Uotm"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="obliqueBeam-Totm">
                {{ $t('glossary.obliqueBeam.Totm') }}
              </b-tooltip>
              <label for="Totm">Totm</label>
              <b-form-input
                id="obliqueBeam-Totm"
                v-model="formobliqueBeam.Totm"
                type="number"
                placeholder="Totm"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="selectedBarrierName === 'maze'">
        <p class="mt-2">
          {{ $t('calcBlindage.modal.fullFillInfo') }}
        </p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="maze-espessuraCalculadaPorOtimizacao">
                {{ $t('glossary.maze.espessuraCalculadaPorOtimizacao') }}
              </b-tooltip>
              <label for="espessuraCalculadaPorOtimizacao">{{ $t('calcBlindage.espessuraCalculadaPorOtimizacao') }}</label>
              <b-form-input
                id="maze-espessuraCalculadaPorOtimizacao"
                v-model="formmaze.espessuraCalculadaPorOtimizacao"
                type="number"
                placeholder="espessuraCalculadaPorOtimizacao"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="maze-espessuraCalculadaPorValorMaximo">
                {{ $t('glossary.maze.espessuraCalculadaPorValorMaximo') }}
              </b-tooltip>
              <label for="espessuraCalculadaPorValorMaximo">{{ $t('calcBlindage.espessuraCalculadaPorValorMaximo') }}</label>
              <b-form-input
                id="maze-espessuraCalculadaPorValorMaximo"
                v-model="formmaze.espessuraCalculadaPorValorMaximo"
                type="number"
                placeholder="espessuraCalculadaPorValorMaximo"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="selectedBarrierName === 'secundary'">
        <p class="mt-2">
          {{ $t('calcBlindage.modal.fullFillInfo') }}
        </p>
        <p>1) {{ $t('calcBlindage.fugaCabecote') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="secundary-P">
                {{ $t('glossary.secundary.P') }}
              </b-tooltip>
              <label for="P">P</label>
              <b-form-input
                id="secundary-P"
                v-model="formsecundary.P"
                type="number"
                placeholder="P"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="secundary-dsca">
                {{ $t('glossary.secundary.dsca') }}
              </b-tooltip>
              <label for="dsca">dsca</label>
              <b-form-input
                id="secundary-dsca"
                v-model="formsecundary.dsca"
                type="number"
                placeholder="dsca"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="secundary-dsec">
                {{ $t('glossary.secundary.dsec') }}
              </b-tooltip>
              <label for="dsec">dsec</label>
              <b-form-input
                id="secundary-dsec"
                v-model="formsecundary.dsec"
                type="number"
                placeholder="dsec"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="secundary-T">
                {{ $t('glossary.secundary.T') }}
              </b-tooltip>
              <label for="T">T</label>
              <b-form-input
                id="secundary-T"
                v-model="formsecundary.T"
                type="number"
                placeholder="T"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <p>2) {{ $t('calcBlindage.espalhamento') }}</p>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <b-tooltip target="secundary-a">
                {{ $t('glossary.secundary.a') }}
              </b-tooltip>
              <label for="a">a</label>
              <b-form-input
                id="secundary-a"
                v-model="formsecundary.a"
                type="number"
                placeholder="a"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <b-tooltip target="secundary-TETA">
                {{ $t('glossary.secundary.TETA') }}
              </b-tooltip>
              <label for="TETA">TETA</label>
              <b-form-input
                id="secundary-TETA"
                v-model="formsecundary.TETA"
                type="number"
                placeholder="TETA"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <p>3) {{ $t('calcBlindage.espOtm') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="secundary-C">
                {{ $t('glossary.secundary.C') }}
              </b-tooltip>
              <label for="C">C</label>
              <b-form-input
                id="secundary-C"
                v-model="formsecundary.C"
                type="number"
                placeholder="C"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="secundary-height">
                {{ $t('glossary.secundary.height') }}
              </b-tooltip>
              <label for="height">{{ $t('calcBlindage.widthOrRightFoot') }}</label>
              <b-form-input
                id="secundary-height"
                v-model="formsecundary.height"
                type="number"
                placeholder="height"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="secundary-width">
                {{ $t('glossary.secundary.width') }}
              </b-tooltip>
              <label for="width">width</label>
              <b-form-input
                id="secundary-width"
                v-model="formsecundary.width"
                type="number"
                placeholder="width"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <b-tooltip target="secundary-n">
                {{ $t('glossary.secundary.n') }}
              </b-tooltip>
              <label for="n">n</label>
              <b-form-input
                id="secundary-n"
                v-model="formsecundary.n"
                type="number"
                placeholder="n"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="secundary-Totm">
                {{ $t('glossary.secundary.Totm') }}
              </b-tooltip>
              <label for="Totm">Totm</label>
              <b-form-input
                id="secundary-Totm"
                v-model="formsecundary.Totm"
                type="number"
                placeholder="Totm"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="selectedBarrierName === 'door'">
        <p class="mt-2">
          {{ $t('calcBlindage.modal.fullFillInfo') }}
        </p>
        <p>{{ $t('calcBlindage.doorArgs') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-P">
                {{ $t('glossary.door.P') }}
              </b-tooltip>
              <label for="P">P</label>
              <b-form-input
                id="door-P"
                v-model="formdoor.P"
                type="number"
                placeholder="P"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-T">
                {{ $t('glossary.door.T') }}
              </b-tooltip>
              <label for="T">T</label>
              <b-form-input
                id="door-T"
                v-model="formdoor.T"
                type="number"
                placeholder="T"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <p>1) {{ $t('calcBlindage.hps') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-dp">
                {{ $t('glossary.door.dp') }}
              </b-tooltip>
              <label for="dp">dp</label>
              <b-form-input
                id="door-dp"
                v-model="formdoor.dp"
                type="number"
                placeholder="dp"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-dr">
                {{ $t('glossary.door.dr') }}
              </b-tooltip>
              <label for="dr">dr</label>
              <b-form-input
                id="door-dr"
                v-model="formdoor.dr"
                type="number"
                placeholder="dr"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-dz">
                {{ $t('glossary.door.dz') }}
              </b-tooltip>
              <label for="dz">dz</label>
              <b-form-input
                id="door-dz"
                v-model="formdoor.dz"
                type="number"
                placeholder="dz"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-UG">
                {{ $t('glossary.door.UG') }}
              </b-tooltip>
              <label for="UG">UG</label>
              <b-form-input
                id="door-UG"
                v-model="formdoor.UG"
                type="number"
                placeholder="UG"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-TETA0">
                {{ $t('glossary.door.TETA0') }}
              </b-tooltip>
              <label for="TETA0">TETA0</label>
              <b-form-input
                id="door-TETA0"
                v-model="formdoor.TETA0"
                type="number"
                placeholder="TETA0"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-TETAZ">
                {{ $t('glossary.door.TETAZ') }}
              </b-tooltip>
              <label for="TETAZ">TETAZ</label>
              <b-form-input
                id="door-TETAZ"
                v-model="formdoor.TETAZ"
                type="number"
                placeholder="TETAZ"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-L0">
                {{ $t('glossary.door.L0') }}
              </b-tooltip>
              <label for="L0">L0</label>
              <b-form-input
                id="door-L0"
                v-model="formdoor.L0"
                type="number"
                placeholder="L0"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-LZ">
                {{ $t('glossary.door.LZ') }}
              </b-tooltip>
              <label for="LZ">LZ</label>
              <b-form-input
                id="door-LZ"
                v-model="formdoor.LZ"
                type="number"
                placeholder="LZ"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-dsca">
                {{ $t('glossary.door.dsca') }}
              </b-tooltip>
              <label for="dsca">dsca</label>
              <b-form-input
                id="door-dsca"
                v-model="formdoor.dsca"
                type="number"
                placeholder="dsca"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-alfa0">
                {{ $t('glossary.door.alfa0') }}
              </b-tooltip>
              <label for="alfa0">alfa0</label>
              <b-form-input
                id="door-alfa0"
                v-model="formdoor.alfa0"
                type="number"
                placeholder="alfa0"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-alfaZ">
                {{ $t('glossary.door.alfaZ') }}
              </b-tooltip>
              <label for="alfaZ">alfaZ</label>
              <b-form-input
                id="door-alfaZ"
                v-model="formdoor.alfaZ"
                type="number"
                placeholder="alfaZ"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <p>2) {{ $t('calcBlindage.hfs') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-dsec">
                {{ $t('glossary.door.dsec') }}
              </b-tooltip>
              <label for="dsec">dsec</label>
              <b-form-input
                id="door-dsec"
                v-model="formdoor.dsec"
                type="number"
                placeholder="dsec"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-dzz">
                {{ $t('glossary.door.dzz') }}
              </b-tooltip>
              <label for="dzz">dzz</label>
              <b-form-input
                id="door-dzz"
                v-model="formdoor.dzz"
                type="number"
                placeholder="dzz"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-L1">
                {{ $t('glossary.door.L1') }}
              </b-tooltip>
              <label for="L1">L1</label>
              <b-form-input
                id="door-L1"
                v-model="formdoor.L1"
                type="number"
                placeholder="L1"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-alfa1">
                {{ $t('glossary.door.alfa1') }}
              </b-tooltip>
              <label for="alfa1">alfa1</label>
              <b-form-input
                id="door-alfa1"
                v-model="formdoor.alfa1"
                type="number"
                placeholder="alfa1"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <p>3) {{ $t('calcBlindage.hpp') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-TETA1">
                {{ $t('glossary.door.TETA1') }}
              </b-tooltip>
              <label for="TETA1">TETA1</label>
              <b-form-input
                id="door-TETA1"
                v-model="formdoor.TETA1"
                type="number"
                placeholder="TETA1"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-alfaTETA1">
                {{ $t('glossary.door.alfaTETA1') }}
              </b-tooltip>
              <label for="alfaTETA1">alfaTETA1</label>
              <b-form-input
                id="door-alfaTETA1"
                v-model="formdoor.alfaTETA1"
                type="number"
                placeholder="alfaTETA1"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <p>4) {{ $t('calcBlindage.hfl') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-df">
                {{ $t('glossary.door.df') }}
              </b-tooltip>
              <label for="df">df</label>
              <b-form-input
                id="door-df"
                v-model="formdoor.df"
                type="number"
                placeholder="df"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-xlab">
                {{ $t('glossary.door.xlab') }}
              </b-tooltip>
              <label for="xlab">xlab</label>
              <b-form-input
                id="door-xlab"
                v-model="formdoor.xlab"
                type="number"
                placeholder="xlab"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <p>5) {{ $t('calcBlindage.hcg') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-d1">
                {{ $t('glossary.door.d1') }}
              </b-tooltip>
              <label for="d1">d1</label>
              <b-form-input
                id="door-d1"
                v-model="formdoor.d1"
                type="number"
                placeholder="d1"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-d2">
                {{ $t('glossary.door.d2') }}
              </b-tooltip>
              <label for="d2">d2</label>
              <b-form-input
                id="door-d2"
                v-model="formdoor.d2"
                type="number"
                placeholder="d2"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-s0">
                {{ $t('glossary.door.s0') }}
              </b-tooltip>
              <label for="s0">s0</label>
              <b-form-input
                id="door-s0"
                v-model="formdoor.s0"
                type="number"
                placeholder="s0"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-s1">
                {{ $t('glossary.door.s1') }}
              </b-tooltip>
              <label for="s1">s1</label>
              <b-form-input
                id="door-s1"
                v-model="formdoor.s1"
                type="number"
                placeholder="s1"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-sr">
                {{ $t('glossary.door.sr') }}
              </b-tooltip>
              <label for="sr">sr</label>
              <b-form-input
                id="door-sr"
                v-model="formdoor.sr"
                type="number"
                placeholder="sr"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <p>5) {{ $t('calcBlindage.espOtm') }}</p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-TVLPORTA">
                {{ $t('glossary.door.TVLPORTA') }}
              </b-tooltip>
              <label for="TVLPORTA">{{ $t('calcBlindage.tvlDoorMeters') }}</label>
              <b-form-input
                id="door-TVLPORTA"
                v-model="formdoor.TVLPORTA"
                type="number"
                placeholder="TVLPORTA"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-alturaPorta">
                {{ $t('glossary.door.alturaPorta') }}
              </b-tooltip>
              <label for="alturaPorta">{{ $t('calcBlindage.doorHeight') }}</label>
              <b-form-input
                id="door-alturaPorta"
                v-model="formdoor.alturaPorta"
                type="number"
                placeholder="alturaPorta"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-larguraPorta">
                {{ $t('glossary.door.larguraPorta') }}
              </b-tooltip>
              <label for="larguraPorta">{{ $t('calcBlindage.doorWidth') }}</label>
              <b-form-input
                id="door-larguraPorta"
                v-model="formdoor.larguraPorta"
                type="number"
                placeholder="larguraPorta"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-maxValPb">
                {{ $t('glossary.door.maxValPb') }}
              </b-tooltip>
              <label for="maxValPb">{{ $t('calcBlindage.maxValPb') }}</label>
              <b-form-input
                id="door-maxValPb"
                v-model="formdoor.maxValPb"
                type="number"
                placeholder="maxValPb"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-maxValHe">
                {{ $t('glossary.door.maxValHe') }}
              </b-tooltip>
              <label for="maxValHe">{{ $t('calcBlindage.maxValHe') }}</label>
              <b-form-input
                id="door-maxValHe"
                v-model="formdoor.maxValHe"
                type="number"
                placeholder="maxValHe"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-tooltip target="door-height">
                {{ $t('glossary.door.height') }}
              </b-tooltip>
              <label for="height">height</label>
              <b-form-input
                id="door-height"
                v-model="formdoor.height"
                type="number"
                placeholder="height"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <b-tooltip target="door-n">
                {{ $t('glossary.door.n') }}
              </b-tooltip>
              <label for="n">n</label>
              <b-form-input
                id="door-n"
                v-model="formdoor.n"
                type="number"
                placeholder="n"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-card no-body>
      <b-table
        v-if="readItems.length"
        striped
        hover
        responsive
        class="position-relative"
        :items="readItems"
        :fields="fields"
      >
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>
        <template #cell(barrierName)="data">
          {{ $t(`barriers.${data.item.barrierName}`) }}
        </template>
        <template #cell(action)="data">
          <b-link
            @click="openModal(data.item.id, data.item.barrierName)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="20"
            />
          </b-link>
        </template>
        <template #cell(viewResult)="data">
          <b-link @click="openViewResultModal(data.item)">
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
              size="20"
            />
          </b-link>
          <span>{{ finalResult(data.item) }}</span>
        </template>
      </b-table>
      <p
        v-else
        class="mt-1 ml-1"
      >
        {{ $t('calcBlindage.withoutRoom') }}
      </p>
    </b-card>
    <b-modal
      id="result"
      ref="modal"
      centered
      :visible="showResultModal"
      cancel-variant="outline-secondary"
      :title="$t('calcBlindage.resultModal.title')"
      :ok-title="$t('calcBlindage.resultModal.ok')"
      :cancel-title="$t('calcBlindage.resultModal.cancel')"
      @cancel="toogleResultModal"
      @close="toogleResultModal"
      @ok="toogleResultModal"
    >
      <div v-if="showResultModal">
        <h3>{{ $t('calcBlindage.resultModal.form') }}</h3>
        <b-row
          v-for="(field, index) in Object.keys(getSelectedForm)"
          :key="`selectedForm-${index}`"
        >
          <b-col>
            {{ field }}
          </b-col>
          <b-col>
            {{ getExponential(parseFloat(selectedForm[field])) }}
          </b-col>
        </b-row>
        <h3 class="mt-2">
          {{ $t('calcBlindage.resultModal.result') }}
        </h3>
        <div v-if="['primary', 'obliqueBeam'].includes(selectedResultBarrierName)">
          <b-row>
            <b-col cols="6">
              {{ $t('calcBlindage.resultModal.valMaxFotons') }}
            </b-col>
            <b-col cols="3">
              B: {{ getExponential(selectedResult.fotons.B) }}
            </b-col>
            <b-col cols="3">
              e: {{ selectedResult.fotons.e.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="6">
              {{ $t('calcBlindage.resultModal.valMaxNeutrons') }}
            </b-col>
            <b-col cols="3">
              B: {{ getExponential(selectedResult.neutrons.B) }}
            </b-col>
            <b-col cols="3">
              e: {{ selectedResult.neutrons.e.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="6">
              {{ $t('calcBlindage.resultModal.otm') }}
            </b-col>
            <b-col cols="3" />
            <b-col cols="3">
              e: {{ selectedResult.otimizacao.e.toFixed(2) }}
            </b-col>
          </b-row>
        </div>
        <div v-if="['secundary'].includes(selectedResultBarrierName)">
          <b-row>
            <b-col cols="6">
              {{ $t('calcBlindage.resultModal.valMaxFotons') }}
            </b-col>
            <b-col cols="3">
              B: {{ getExponential(selectedResult.fotons.B) }}
            </b-col>
            <b-col cols="3">
              e: {{ selectedResult.fotons.e.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="6">
              {{ $t('calcBlindage.resultModal.valMaxPaciente') }}
            </b-col>
            <b-col cols="3">
              B: {{ getExponential(selectedResult.paciente.B) }}
            </b-col>
            <b-col cols="3">
              e: {{ selectedResult.paciente.e.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="6">
              {{ $t('calcBlindage.resultModal.otm') }}
            </b-col>
            <b-col cols="3" />
            <b-col cols="3">
              e: {{ selectedResult.otimizacao.e.toFixed(2) }}
            </b-col>
          </b-row>
        </div>
        <div v-if="['door'].includes(selectedResultBarrierName)">
          <b-row>
            <b-col cols="8">
              HPS
            </b-col>
            <b-col cols="4">
              {{ getExponential(selectedResult.doseHps) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="8">
              HFS
            </b-col>
            <b-col cols="4">
              {{ getExponential(selectedResult.doseHfs) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="8">
              HPP
            </b-col>
            <b-col cols="4">
              {{ getExponential(selectedResult.doseHpp) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="8">
              HFL
            </b-col>
            <b-col cols="4">
              {{ getExponential(selectedResult.doseHfl) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="8">
              HNS
            </b-col>
            <b-col cols="4">
              {{ getExponential(selectedResult.doseHns) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="8">
              HCG
            </b-col>
            <b-col cols="4">
              {{ getExponential(selectedResult.doseHcg) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="8">
              {{ $t('calcBlindage.resultModal.espessuraParcialParafina') }}
            </b-col>
            <b-col cols="4">
              {{ selectedResult.espessuraParcialParafina.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="8">
              {{ $t('calcBlindage.resultModal.espessuraParcialChumbo') }}
            </b-col>
            <b-col cols="4">
              {{ selectedResult.espessuraParcialChumbo.toFixed(2) }}
            </b-col>
          </b-row>
        </div>
        <b-row
          v-if="['primary', 'secundary', 'maze', 'obliqueBeam'].includes(selectedResultBarrierName)"
          class="mt-1"
        >
          <b-col>
            {{ $t('calcBlindage.resultModal.espessuraFinal') }}
          </b-col>
          <b-col>
            {{ selectedResult.espessuraFinal.toFixed(2) }}
          </b-col>
          <b-col v-if="selectedResultBarrierName === 'primary'">
            {{ $t('calcBlindage.resultModal.larguraCinturaoValMin') }}
          </b-col>
          <b-col v-if="selectedResultBarrierName === 'primary'">
            {{ selectedResult.larguraCinturao.valMin.toFixed(2) }}
          </b-col>
          <b-col v-if="selectedResultBarrierName === 'primary'">
            {{ $t('calcBlindage.resultModal.larguraCinturaoValMax') }}
          </b-col>
          <b-col v-if="selectedResultBarrierName === 'primary'">
            {{ selectedResult.larguraCinturao.valMax.toFixed(2) }}
          </b-col>
        </b-row>
        <div
          v-else
          class="mt-1"
        >
          <b-row>
            <b-col>
              {{ $t('calcBlindage.resultModal.custoParafinaTotal') }}
            </b-col>
            <b-col>
              {{ selectedResult.custoParafinaTotal.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              {{ $t('calcBlindage.resultModal.custoChumboTotal') }}
            </b-col>
            <b-col>
              {{ selectedResult.custoChumboTotal.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              {{ $t('calcBlindage.resultModal.espessuraEscolhidaParafina') }}
            </b-col>
            <b-col>
              {{ selectedResult.espessuraEscolhidaParafina.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              {{ $t('calcBlindage.resultModal.espessuraEscolhidaChumbo') }}
            </b-col>
            <b-col>
              {{ selectedResult.espessuraEscolhidaChumbo.toFixed(2) }}
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    <b-row>
      <b-button
        v-if="allBlindageCalcFinished"
        class="ml-1 mt-1"
        @click="generateReport"
      >
        {{ $t('calcBlindage.generateReport') }}
      </b-button>
    </b-row>
    <b-row
      v-for="r of ProjectReports"
      :key="r.id"
    >
      <a
        v-if="r.projectPdfUri"
        target="_blank"
        :href="apiDataUri(r.projectPdfUri)"
      >
        <b-button class="ml-1 mt-1">
          {{ $t('calcBlindage.viewReport') }} {{ new Date(r.createdAt).toLocaleDateString() }}
        </b-button>
      </a>
      <a
        v-if="r.projectSummaryPdfUri"
        target="_blank"
        :href="apiDataUri(r.projectSummaryPdfUri)"
      >
        <b-button class="ml-1 mt-1">
          {{ $t('calcBlindage.viewSummary') }} {{ new Date(r.createdAt).toLocaleDateString() }}
        </b-button>
      </a>
      <feather-icon
        icon="TrashIcon"
        class="mt-2 ml-2"
        color="danger"
        size="20"
        @click="deleteReport(r)"
      />
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BLink,
  BButton,
  BTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BModal,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BLink,
    BButton,
    BTooltip,
  },
  data() {
    return {
      items: [],
      project: null,
      roomId: null,
      blindages: null,
      ProjectReports: [],
      showModal: false,
      showResultModal: false,
      selectedForm: null,
      selectedResult: null,
      selectedResultBarrierName: null,
      selectedBlindageId: null,
      selectedBarrierName: null,
      formprimary: {
        P: 0.00002,
        C: 500,
        d: 7.1,
        U: 0.14285714285,
        T: 1,
        Uotm: 0.14285714285,
        Totm: 1,
        dm: 5.3,
        cMax: 0.5,
        n: 0.5,
        height: 3.6,
        width: 3.75,
      },
      formsecundary: {
        P: 0.00002,
        dsca: 1,
        dsec: 6.7,
        T: 1,
        Totm: 1,
        TETA: 29,
        a: (3.05 * (10 ** -3)),
        n: 0.5,
        C: 500,
        height: 3.6,
        width: 1.5,
      },
      formobliqueBeam: {
        P: 0.00002,
        d: 8.2,
        U: 2 / 7,
        T: 1 / 40,
        n: 1,
        C: 500,
        height: 3.8,
        width: 7,
        Uotm: 2 / 7,
        Totm: 1,
      },
      formmaze: {
        espessuraCalculadaPorOtimizacao: 1.10,
        espessuraCalculadaPorValorMaximo: 1.10,
      },
      formdoor: {
        T: 1 / 8,
        P: 0.00002,
        dp: 4.5,
        dr: 7.3,
        dz: 7,
        UG: 1 / 7,
        L0: 1.8,
        LZ: 2.5,
        height: 3.6,
        TETA1: 55,
        TETA0: 31,
        TETAZ: 60,
        alfa0: (5.22 * (10 ** -3)),
        alfaZ: (5.24 * (10 ** -3)),
        dsec: 6,
        dzz: 10.5,
        L1: 3.4,
        alfa1: (5.26 * (10 ** -3)),
        dsca: 1,
        alfaTETA1: (5.9 * (10 ** -4)),
        df: 8.5,
        xlab: 1.1,
        d1: 6.60,
        d2: 8.80,
        s0: 2.40,
        s1: 2.15,
        sr: 342.4,
        n: 0.25,
        TVLPORTA: 0.02119,
        alturaPorta: 2.10,
        larguraPorta: 1.50,
        maxValPb: 12,
        maxValHe: 15,
      },
    }
  },
  computed: {
    apiDataUri() { return uri => `${process.env.VUE_APP_API_URL}/files/${uri}` },
    allBlindageCalcFinished() {
      const blindageSize = this.items.length
      const fullFilledSize = this.items.filter(e => e.result).length
      return blindageSize && (blindageSize === fullFilledSize)
    },
    getExponential() { return val => (val ? val.toExponential(2) : 'N/A') },
    finalResult() {
      return item => {
        const result = JSON.parse(item.result)
        const { barrierName } = item
        if (!result) return null
        if (barrierName === 'door') return `${result.espessuraEscolhidaChumbo.toFixed(2)} & ${result.espessuraEscolhidaParafina.toFixed(2)}`
        return result.espessuraFinal.toFixed(2)
      }
    },
    getSelectedForm() { return this.selectedForm },
    getSelectedResult() { return this.selectedResult },
    readItems() { return this.items },
    fields() {
      return [
        { key: 'name', label: this.nameField, sortable: true },
        { key: 'barrierName', label: this.barrierNameField, sortable: true },
        { key: 'action', label: this.actionField, sortable: true },
        { key: 'viewResult', label: this.viewResultField, sortable: true },
      ]
    },
    nameField() { return this.$t('calcBlindage.table.name') },
    barrierNameField() { return this.$t('calcBlindage.table.barrierName') },
    actionField() { return this.$t('calcBlindage.table.action') },
    viewResultField() { return this.$t('calcBlindage.table.viewResult') },
  },
  async created() { await this.load() },
  methods: {
    deleteReport(r) {
      const { id, ProjectId } = r
      this.$swal({
        ...this.$t('calcBlindage.toast.deleteReport'),
        buttonsStyling: false,
        showDenyButton: true,
        customClass: { confirmButton: 'btn btn-danger mr-1', denyButton: 'btn btn-primary' },
      }).then((result => {
        if (!result.isConfirmed) { return }
        this.destroyReport(id, ProjectId)
          .then(() => { this.load() })
      }))
    },
    destroyReport(id, ProjectId) {
      return this.$store.dispatch('project/deleteReport', { id, ProjectId })
    },
    toogleCreateModal() {
      this.showModal = !this.showModal
    },
    openModal(id, barrierName) {
      this.selectedBlindageId = id
      this.selectedBarrierName = barrierName
      this.toogleCreateModal()
    },
    toogleResultModal() {
      this.showResultModal = !this.showResultModal
    },
    openViewResultModal(blindage) {
      if (!blindage.result || blindage.result === '') {
        this.$swal({
          icon: 'error',
          buttonsStyling: false,
          ...this.$t('calcBlindage.mustCalc'),
          customClass: { confirmButton: 'btn btn-primary' },
        })
      } else {
        this.selectedForm = JSON.parse(blindage.form)
        this.selectedResult = JSON.parse(blindage.result)
        this.selectedResultBarrierName = blindage.barrierName
        this.toogleResultModal()
      }
    },
    async sendCalc() {
      const data = await this.$store.dispatch(
        'project/calc',
        {
          projectId: this.project.id,
          roomId: this.roomId,
          blindageId: this.selectedBlindageId,
          payload: this[`form${this.selectedBarrierName}`],
        },
      )
      // eslint-disable-next-line
      let selectedBlindage = this.items.find(e => e.id === this.selectedBlindageId)
      selectedBlindage.form = JSON.stringify(data.form)
      selectedBlindage.result = JSON.stringify(data.result)
      this.$swal({
        icon: 'success',
        buttonsStyling: false,
        ...this.$t('calcBlindage.calcFinished'),
        customClass: { confirmButton: 'btn btn-primary' },
      })
      this.toogleCreateModal()
    },
    async generateReport() {
      const { id } = this.$route.query
      await this.$store.dispatch('project/generateReport', { id })
      this.$swal({
        icon: 'success',
        buttonsStyling: false,
        ...this.$t('calcBlindage.reportFinished'),
        customClass: { confirmButton: 'btn btn-primary' },
      })
      await this.load()
    },
    async load() {
      const { id } = this.$route.query
      this.project = await this.$store.dispatch('project/get', { id })
      this.roomId = this.project.Rooms[0].id
      this.ProjectReports = this.project.ProjectReports
      const bld = this.project.Rooms[0].Blindages.map(e => ({ ...e, barrierName: e.Barrier.name }))
      this.blindages = bld.filter(e => e.barrierName === 'primary')
      this.blindages.push(...bld.filter(e => e.barrierName === 'obliqueBeam'))
      this.blindages.push(...bld.filter(e => e.barrierName === 'secundary'))
      this.blindages.push(...bld.filter(e => e.barrierName === 'maze'))
      this.blindages.push(...bld.filter(e => e.barrierName === 'door'))
      this.items = this.blindages
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@media (max-width: 767px){
  .small-devices-filterby{
    display: block;
  }
}
.dropdown button {
    padding: 0px;
}
.em-dropdown .dropdown-menu {
  max-height: 100px;
  overflow-y: hidden;
}
.dropdown-item{
  padding: 0 4px;
}
</style>
